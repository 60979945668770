<template>
  <div>
    <AddressWidget
      class="mb-3"
      style="padding-right: 30px; margin: auto; width: fit-content"
      :address-id="address.addressId.toString()"
      :first-line="address.add1"
      :second-line="address.add2"
      :town="address.town"
      :county="address.county"
      :post-code="address.postcode"
      :start-date="address.fromDate"
      :end-date="address.untilDate"
      no-actions
      @editAddressClickedToggle="editAddressClicked($event, address.addressId)"
    ></AddressWidget>
    <CreditInformation
      v-show="showCreditInfo"
      v-on:selected-address-id="selectedAddressIdChanged"
      class="mt-4"
      :contact-id="selectedContact.contactId"
      :selectedAddressIdProp="selectedAddressId"
      @fetchContactAddress="fetchContactAddresses(selectedContact.contactId)"
    >
    </CreditInformation>
  </div>
</template>

<script>
import AddressWidget from "@/components/address-widget"

import CreditInformation from "@/components/forms/credit-info-sc"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AdressAndCreditInformation",
  components: { CreditInformation, AddressWidget },

  data: () => ({
    selectedAddressId: null,

    showCreditInfo: false,
  }),

  props: {
    address: {
      type: Object,
    },
    lastOne: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("contacts", ["contactAddressItems", "selectedContact"]),
  },
  //   mounted() {
  //     this.showCreditInfo = this.showNewAddressInformation
  //   },
  watch: {
    // showNewAddressInformation(newVal) {
    //   this.showCreditInfo = newVal
    // },
  },
  methods: {
    ...mapActions("contacts", ["fetchContactAddresses"]),
    selectedAddressIdChanged(value) {
      this.contactAddressItems.addressId = value
      this.selectedAddressId = value
    },
    editAddressClicked(toggleValue, id) {
      console.log(toggleValue, id, "toggle")

      this.showCreditInfo = toggleValue
      if (this.lastOne && toggleValue) {
        this.$emit("lastOneEditShowClicked", false)
      } else if (this.lastOne && !toggleValue) {
        this.$emit("lastOneEditShowClicked", true)
      }
      this.selectedAddressId = id
    },
  },
}
</script>
